.solde {
  position: sticky;
  left: 0px;
  border-right: 7px solid #f5f5f5 !important;
}

.cattegory {
  left: 0px;
  border-right: 7px solid #f5f5f5 !important;
}
.synthese {
  left: 410px;
  border-right: 7px solid #f5f5f5 !important;
}
.objectif {
  left: 205px;
  border-right: 7px solid #f5f5f5 !important;
}
.filterSolde {
  display: flex;
  justify-content: space-between;
}
.action {
  position: sticky;
  right: 0px;
}
.syntheseNotObjective {
  left: 220px;
  border-right: 7px solid #f5f5f5 !important;
}