body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  .month-total {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
  }
  .category-thead-container {
    background-color: #fff !important;
    color: #000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .category-thead {
    display: flex;
    justify-content: space-between;
  }
  .category-thead-Montant {
    display: flex;
    justify-content: flex-end;
  }
  .category-thead-Montant > p {
    margin: 0;
    color: #000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .category-thead > p {
    margin: 0;
    color: #000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  
  .elements {
    display: flex;
    flex-direction: column;
    left: auto !important;
    top: auto !important;
    border: 1px solid transparent;
    position: relative;
  }
  .elements.dragging-over {
    border-bottom: 2px dashed red;
  }
  .elements.dragging-over-treso {
    border: 2px dashed #33cc00;
  }
  .elements.dragging-over-compta {
    border: 2px dashed #3399cc;
  }
  
  .elements > p {
    margin: 0;
    text-align: right;
    text-decoration: underline;
    padding: 8px;
  }
  
  .element {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 0 10px;
    position: relative;
  }
  .element:not(.ghosting) {
    transform: none !important;
  }
  
  .element:last-child {
    margin-bottom: 0;
  }
  
  .table-td {
    display: table-cell;
    vertical-align: top;
    border-bottom: 2px solid #f5f5f5; 
  }
  
  .ghosting {
    background: red;
  }
  
  .drag-and-drop {
    color: gray;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  
  .not-moving {
    transform: none !important;
  }
  .namecolumn {
    display: flex;
    justify-content: space-between;
  }
  
  .green {
    background-color: #33cc00 !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #f0f0f0;
  }
  .ant-table-thead > tr > th {
    background-color: #fff;
  }
  
  .customerTableTreso {
    background-color: #33cc00 !important;
    border-right: 1px solid #f5f5f5 !important;
    color: #ffffff !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  
  .customerTableCompta {
    background-color: #3399cc !important;
    border-right: 1px solid #f5f5f5 !important;
    color: #ffffff !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .ColoumnHeaderTitle {
    border-right: 7px solid #f5f5f5 !important;
    background-color: #f5f5f5 !important;
    color: #000000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .ColoumnHeaderTitleDelete {
    background-color: #f5f5f5 !important;
    color: #000000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .customerSynthese {
    background-color: #000000 !important;
    border-right: 1px solid #f5f5f5 !important;
    color: #ffffff !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .customerObjectif {
    background-color: #818181 !important;
    border-right: 1px solid #f5f5f5 !important;
    color: #ffffff !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .btExpand {
    background-color: transparent;
    border: transparent;
  }
  
  
  /* .rangePickerSynthese .ant-picker-input {
    display: none;
  }
  .rangePickerSynthese .ant-picker-range-separator {
    display: none;
  } */
  .synthseTable {
    display: flex;
    justify-content: space-between;
  }
  